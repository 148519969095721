@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/mixin';

.qnaCollapse {
  align-items: center;
  color: #272e40;
  .titleWrapper {
    position: relative;
    display: flex;
    align-items: center;
    transition: font-weight 0.15s ease-in-out, background-color 0.15s ease-in-out;
    justify-content: space-between;
    height: 60px;
    box-sizing: border-box;
    cursor: pointer;
    @include desktop {
      height: 76px;
      padding: 0 16px;
    }
    @media (hover: hover) {
      &:hover {
        background-color: $base_gray2;
        border-radius: 4px;
      }
    }
    .title {
      display: flex;
      align-items: center;
      .icon {
        position: relative;
        top: -2px;
        margin-right: 10px;
        width: 16px;
        height: 16px;
        @include desktop {
          width: 16px;
          height: 16px;
          margin-right: 16px;
        }
      }
      .desktopTitle {
        font-size: 18px;
      }
    }
    .arrowIcon {
      transition: transform 0.15s ease-in-out;
      width: 16px;
      height: 16px;
      fill: $base_gray4;
      @include desktop {
        width: 24px;
        height: 24px;
      }
    }
  }
  .contentWrapper {
    transition: height 0.3s ease-out, opacity 0.3s ease-out;
    overflow: hidden;
    .content {
      padding-left: 26px;
      white-space: pre-line;
      @include desktop {
        padding-left: 42px;
      }
      .desktopContent {
        font-size: 18px;
        line-height: 32px;
        color: $base_primary;
      }
    }
  }
}

.isOpen {
  .title {
    font-weight: bold;
  }
  .contentWrapper {
    padding-bottom: 32px;
    @include desktop {
      padding-bottom: 40px;
    }
  }
  .arrowIcon {
    transform: rotate(180deg);
  }
}
