@import 'src/styles/mixin';
@import 'src/styles/functions';
@import 'src/design-system/color-palette/color-palette';

.section {
  padding: 72px 32px 0 32px;
  background: $base_gray2;
  @include desktop {
    padding: 0;
    display: flex;
    height: 880px;
  }
  .mobileContent {
    @include animationHide();
  }
  .mobileTitle {
    @include mobileTitle();
  }
  .subTitle {
    color: $base_light;
    margin-top: 16px;
  }
  .caption {
    margin-top: 8px;
    color: $base_gray6;
  }
  .section4MobileImage {
    display: block;
    width: getVw(220);
    margin: 0 auto;
    margin-top: 32px;
    @include animationHide();
    transition-delay: 0.4s;
  }

  .leftSection {
    position: relative;
    flex-basis: 50%;
    .desktopSection4Image {
      position: absolute;
      top: 123px;
      right: 109px;
      width: 360px;
      height: 645.6px;
      @include animationHide();
      transition-delay: 0.4s;
    }
  }
  .rightSection {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    .content {
      margin-left: 32px;
      @include animationHide();
      .desktopTitle {
        @include desktopTitle();
      }
      .desktopSubTitle {
        @include desktopSubTitle();
        margin-top: 28px;
      }
      .desktopCaption {
        color: $base_gray6;
        margin-top: 16px;
      }
    }
  }

  .gray {
    color: $base_16;
  }
}

.view {
  @include animationView();
}
.imageView {
  @include animationView();
}
