@import 'src/styles/mixin';
@import 'src/styles/functions';
@import 'src/design-system/color-palette/color-palette';

.section {
  padding: 72px 32px 0 32px;
  @include desktop {
    padding: 0;
    display: flex;
    height: 880px;
  }
  .mobileContent {
    @include animationHide();
  }

  .mobileTitle {
    @include mobileTitle();

  }
  .subTitle {
    color: $base_light;
    margin-top: 16px;
  }

  .section3MobileImage {
    display: block;
    width: getVw(220);
    margin: 0 auto;
    margin-top: 32px;
    @include animationHide();
    transition-delay: 0.4s;
  }

  .leftSection {
    position: relative;
    flex-basis: 50%;
    .content {
      position: absolute;
      top: 340px;
      right: 194px;
      @include animationHide();
      .desktopTitle {
        @include desktopTitle();
      }
      .desktopSubTitle {
        @include desktopSubTitle();
        margin-top: 24px;
      }
    }
  }
  .rightSection {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    .desktopSection3Image {
      width: 327px;
      height: 590px;
      @include animationHide();
      transition-delay: 0.4s;
    }
  }

  .gray {
    color: $base_16;
  }
}

.view {
  @include animationView();
}
.imageView {
  @include animationView();
}
