@import '../../styles/mixin';
@import '../../styles/variables';
@import '../../design-system/color-palette/color-palette';

.container {
  margin-top: 56px;
  padding: 0 32px;
  @include desktop {
    margin: 0 auto;
    margin-top: 64px;
    max-width: $desktop-width;
  }
  .titleWrapper {
    padding-top: 40px;
    margin-bottom: 8px;
    @include  desktop {
      padding-top: 144px;
      margin-bottom: 72px;
    }
  }
  .desktopTitle {
    margin: 0;
    color: $base_dark;
    font-size: 40px;
    line-height: 74px;
  }

  .br {
    border-bottom: 1px solid $base_gray3;
    width: 100%;
  }
}
