@import 'src/styles/mixin';
@import 'src/styles/functions';
@import 'src/styles/animations';

.section1 {
  @include desktop {
    display: none;
  }
  margin-top: 56px;
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: 100vw;
  .content {
    display: flex;
    justify-content: center;
    .copyWriteWrapper {
      position: relative;
      animation: copyWriteFadeinUp 0.5s;
      padding: 0 32px;
      .copyWrite {
        width: 100%;
      }
    }
  }
  .modelWrapper {
    display: flex;
    justify-content: flex-end;
    margin-left: 33px;
    margin-top: 24px;
    position: relative;
    .tradeCount {
      position: absolute;
      left: 0;
      top: 0;
    }
    .model {
      object-fit: contain;
      object-position: right bottom;
      width: getVw(327);
      height: getVh(445);
    }
  }
  .appDownloadButtonWrapper {
    z-index: 2;
    position: fixed;
    right: 17px;
    bottom: 17px;
  }
}
