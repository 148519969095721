@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/variables';
@import 'src/styles/mixin';
.desktopHeaderWrapper {
  @include mobile {
    display: none;
  }
  width: 100%;
  position: fixed;
  top: 0;
  background: white;
  z-index: 2;
  cursor: pointer;
  .header {
    box-sizing: border-box;
    max-width: $desktop-width;
    width: 100%;
    margin: 0 auto;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 88px;
      height: 27.5px;
    }

    .section {
      display: flex;
      .listItem {
        &:not(:last-child) {
          margin-right: 56px;
        }
      }
      .buttonWrapper {
        cursor: pointer;
        display: inline-block;
        padding: 12px;
        transition: background 0.15s ease-in-out;
        @media (hover: hover) {
          &:hover {
            background-color: $base_gray2;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

.isNotTop {
  opacity: 0.8;
}
