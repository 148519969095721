@import 'src/design-system/color-palette/color-palette';

.header {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
  .icon {
    padding: 12px;
  }
}

.section {
  &:first-child {
    margin-bottom: 36px;
  }

  .subTitle {
    padding-left: 32px;
    margin-bottom: 12px;
  }

  .listItem {
    display: inline-block;
    padding: 12px 0;
    padding-left: 32px;
    width: 100%;
  }

}
