@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/functions';

.carousel {
    padding-right: getVw(88);

  :global {
    .swiper-container {
      overflow: visible !important;
    }
  }
}
