@import 'src/styles/variables';
@import 'src/design-system/color-palette/color-palette';

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}


@mixin mobile {
  @media (max-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin mobileTitle {
  margin: 0;
  font-size: 32px;
  line-height: 48px;
  color: $base_dark;
  -webkit-text-stroke: 0.2px $base_dark;
}

@mixin desktopTitle {
  margin: 0;
  font-size: 48px;
  line-height: 72px;
  color: $base_dark;
  -webkit-text-stroke: 0.3px $base_dark;
}

@mixin desktopSubTitle {
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  color: $base_light;
}

@mixin animationHide {
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  transform: translateY(40px);
  opacity: 0;
}
@mixin animationView {
  transform: translateY(0) !important;
  opacity: 1 !important;
}

:global {
  .mobile {
    @include desktop {
      display: none !important;
    }
  }
}
:global {
  .desktop {
    @include mobile {
      display: none !important;
    }
  }
}
