@import 'src/styles/mixin';
@import 'src/styles/functions';
@import 'src/design-system/color-palette/color-palette';

.section {
  padding: 72px 0 72px 32px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  max-width: 100vw;
  @include desktop {
    padding: 0;
    display: flex;
    height: 776px;
  }
  .mobileTitle {
    @include mobileTitle();
  }
  .mobileContent {
    @include animationHide();
  }
  .carousel {
    @include animationHide();
    transition-delay: 0.4s;
  }
  .subTitle {
    color: $base_light;
    margin-top: 16px;
  }
  .caption {
    margin-top: 8px;
    color: $base_gray6;
  }
  .leftSection {
    position: relative;
    flex-basis: 50%;
    .content {
      @include animationHide();
      position: absolute;
      top: 340px;
      right: 194px;
      .desktopTitle {
        @include desktopTitle();
      }
      .desktopSubTitle {
        @include desktopSubTitle();
        margin-top: 24px;
      }
    }
  }
  .rightSection {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    .dealerProfileImage1 {
      width: 260px;
      height: 424px;
      box-shadow: -15px 15px 30px rgba(0, 0, 0, 0.15);
      margin-right: 22px;
      @include animationHide();
      transition-delay: 0.3s;
    }
    .dealerProfileImage2 {
      width: 260px;
      height: 424px;
      box-shadow: -15px 15px 30px rgba(0, 0, 0, 0.15);
      margin-right: 22px;
      @include animationHide();
      transition-delay: 0.6s;
    }
  }
}


.view {
  @include animationView();
}
.imageView {
  @include animationView();
}
