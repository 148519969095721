@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/mixin';

.faq {
  margin-bottom: 64px;
  @include desktop {
    margin-bottom: 88px;
  }

  .paragraph {
    margin-top: 8px;
  }
  .link {
    color: $brandcolor_dark;
    text-decoration: underline;
    cursor: pointer;
  }
}
