@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import './nomalize.css';
@import './mixin';

* {
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

body {
  padding-bottom: env(safe-area-inset-bottom); // IOS 11.0 이상 (신)
  padding-bottom: constant(safe-area-inset-bottom); // IOS 11.0 버전 (구)
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  all: unset;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
}
