@import 'src/styles/mixin';
@import 'src/styles/animations';

.desktopSection1 {
  @include mobile {
    display: none;
  }
  display: flex;
  margin-top: 64px;
  height: calc(100vh - 64px);
  .leftSection {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .copyWrite {
      width: 472px;
      height: 186px;
      animation: copyWriteFadeinUp 0.5s;
    }
    .tradedCarCountWrapper {
      margin-top: 48px;
    }
    .appDownloadButtons {
      margin-top: 64px;
    }
  }
  .rightSection {
    flex-basis: 50%;
    position: relative;
    .model {
      width: 565px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}
