@import 'src/styles/mixin';
@import 'src/design-system/color-palette/color-palette';

.section {
  background: $base_gray2;
  height: 258px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @include desktop {
    height: 400px;
  }
  .mobileSubTitle {
    margin: 0;
    font-size: 22px;
    line-height: 38px;
    @include animationHide();
    @include desktop {
      display: none;
    }
  }
  .desktopSubTitle {
    margin: 0;
    font-size: 32px;
    line-height: 56px;
    @include animationHide();
    @include mobile {
      display: none;
    }
  }
  .view {
    @include animationView();
  }

  .gray {
    color: $base_16;
  }
}
