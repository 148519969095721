@import 'src/styles/mixin';

.header {
  @include desktop {
    display: none;
  }
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  background: white;
  z-index: 2;
  .logo {
    width: 72px;
    height: 22.5px;
    padding: 16.75px 24px;
  }
  .icon {
    padding: 12px;
    width: 24px;
    height: 24px;
  }
}

.isNotTop {
  opacity: 0.8;
}
.sideMenu {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 3;
  .container {
    display: flex;
    justify-content: flex-end;
    .content {
      box-sizing: border-box;
      height: 100%;
      background: white;
      width: 280px;
    }
  }
}
.dimmer {
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
