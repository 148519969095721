@import 'src/styles/functions';
@import 'src/styles/mixin';

.safeTradeCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: getVw(240);
  border-radius: 4px;
  padding: 24px;
  box-sizing: border-box;
  box-shadow: -15px 15px 30px rgba(0, 0, 0, 0.15);
  @include desktop {
    width: 296px;
    height: 296px;
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
  .title {
    margin: 0;
    font-size: 18px;
    line-height: 28px;
    @include desktop {
      font-size: 20px;
      line-height: 28px;
    }
  }
  .image {
    margin-top: getVh(14);
    width: getVw(88);
    height: getVw(88);
    align-self: center;
    @include desktop {
      margin-top: 25px;
      width: 96px;
      height: 96px;
    }
  }
  .descriptionWrapper {
    margin-top: getVh(14);
    white-space: pre-line;
    @include desktop {
      margin-top: 25px;
    }
  }
}
