@import 'src/styles/functions';

.carousel {
  padding-right: getVw(145);
  margin-top: 40px;

  :global {
    .swiper-container {
      overflow: visible !important;
    }
  }
  .dealerProfile {
    width: 100%;
    box-shadow: -15px 15px 30px rgba(0, 0, 0, 0.15);
  }
}
