@import 'src/styles/mixin';
@import 'src/styles/functions';
@import 'src/design-system/color-palette/color-palette';

.section {
  width: 100%;
  overflow: hidden;
  padding: 72px 0 0 32px;
  box-sizing: border-box;
  background: $base_gray2;
  @include desktop {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 800px;
  }
  .mobileTitle {
    @include mobileTitle();
    @include animationHide();
  }
  .safeTradeCarouselWrapperMobile {
    margin-top: 40px;
    margin-bottom: 72px;
    @include animationHide();
    transition-delay: 0.4s;
  }
  .desktopTitle {
    @include desktopTitle();
    @include animationHide();
    text-align: center;
  }
  .safeTradeCarouselWrapperDesktop {
    margin-top: 64px;
    display: flex;
    .safeTradeCard1 {
      @include animationHide();
      transition-delay: 0.3s;
    }
    .safeTradeCard2 {
      @include animationHide();
      transition-delay: 0.6s;
    }
    .safeTradeCard3 {
      @include animationHide();
      transition-delay: 0.9s;
    }
  }
}

.view {
  @include animationView();
}
.imageView {
  @include animationView();
}
