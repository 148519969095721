@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/mixin';

.otherQuestion {
  margin: 32px 0 72px 0;
  @include desktop {
    margin: 72px 0 152px 0;
  }
  .title {
    color: $base_light;
  }
  .subTitle {
    color: $base_light;
    margin-top: 8px;
    @include desktop {
      margin-top: 16px;
    }
  }
  .icon {
    fill: $base_gray4;
    margin-right: 7px;
    width: 16px;
    height: 16px;
  }
  .buttonWrapper {
    margin-top: 16px;
    @include desktop {
      margin-top: 32px;
    }
  }
  .downloadButton {
    padding: 12px;
    font-size: 12px;
    line-height: 16px;
    display: inline-flex;
  }
}
