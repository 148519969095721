@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/mixin';

.download {
  @include mobile {
    display: none;
  }
  box-sizing: border-box;

  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $base_gray3;
  box-shadow: 0 2px 4px $base_8;
  border-radius: 4px;
  transition: border 0.15s ease-in-out;
  &:first-child {
    margin-right: 8px;
  }
  @media (hover: hover) {
    &:hover {
      border-color: $base_gray5;
    }
  }
}

.small {
  width: 134px;
  height: 48px;
  .googlePlay {
    width: 102px;
    height: 22px;
  }

  .appstore {
    width: 91px;
    height: 22px;
  }
}

.big {
  width: 148px;
  height: 57px;
  .googlePlay {
    width: 116px;
    height: 25px;
  }

  .appstore {
    width: 104px;
    height: 25px;
  }

}
