@import 'src/design-system/color-palette/color-palette';

.button {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
}
.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  fill: $base_white;
}
